import './application.css';
import '@hotwired/turbo-rails';
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';
import './prismjs.js';

console.log('Vite ⚡️ Rails');
console.log(
  'Visit the guide for more information: ',
  'https://vite-ruby.netlify.app/guide/rails'
);

const application = Application.start();
registerControllers(
  application,
  import.meta.glob('./controllers/**/*_controller.{ts,tsx}', { eager: true })
);
